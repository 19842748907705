import dynamic from 'next/dynamic';
import Layout from '../Layout';
import MaxWidth from '../MaxWidth';
import {BREAKPOINT} from 'aac-components/utils/styles';
import {createRef, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import JumpToSection from '../PageBuilder/JumpToSection';

// blocks
import Hero from '../PageBuilder/Hero';
import FacilityCtaSection from '../PageBuilder/FacilityCtaSection';
import {isMappableArray} from 'aac-components/utils/helpers';
const HeroVeterans = dynamic(() => import('../PageBuilder/HeroVeterans'));
const BlockWithImage = dynamic(() => import('../PageBuilder/BlockWithImage'));
const BlockWithIntroCopy = dynamic(() => import('../PageBuilder/BlockWithIntroCopy'));
const BlockWithVob = dynamic(() => import('../PageBuilder/BlockWithVob'));
const BlockFullWidth = dynamic(() => import('../PageBuilder/BlockFullWidth'));
const BlockWithVeteranCta = dynamic(() => import('../PageBuilder/BlockWithVeteranCta'));

const PageBuilderTemplate = (props) => {
    const {
        acf: {
            content_block_builder = [],
            include_jump_to_section = true,
            jump_to_section_copy = '',
            hero: {use_veteran_hero = false},
            facility_slider: {
                show_facility_slider = true,
                facility_slider_position = 'after_third',
            } = {},
        },
        slug = '',
    } = props?.data;

    const showMidPageCta =
        show_facility_slider && facility_slider_position === 'after_third';

    const blocksToRender = showMidPageCta
        ? [
              ...content_block_builder.slice(0, 2),
              {
                  block_type: 'facility_cta',
              },
              ...content_block_builder.slice(2),
          ]
        : content_block_builder;

    return (
        <Layout>
            {use_veteran_hero ? (
                <HeroVeterans {...props?.data} />
            ) : (
                <Hero {...props?.data} />
            )}
            <MaxWidth>
                {include_jump_to_section && (
                    <JumpToSection blocks={blocksToRender} copy={jump_to_section_copy} />
                )}
                <div className="blocks">
                    {isMappableArray(blocksToRender) &&
                        blocksToRender.map((block, index) => {
                            let BlockType;
                            switch (block?.block_type) {
                                case 'withImage':
                                    BlockType = BlockWithImage;
                                    break;
                                case 'withVob':
                                    BlockType = BlockWithVob;
                                    break;
                                case 'fullWidth':
                                    BlockType = BlockFullWidth;
                                    break;
                                case 'withVeteranCta':
                                    BlockType = BlockWithVeteranCta;
                                    break;
                                case 'facility_cta':
                                    BlockType = FacilityCtaSection;
                                    break;
                                default:
                                    BlockType = BlockWithIntroCopy;
                                    break;
                            }

                            return (
                                <div
                                    key={index}
                                    id={`block-${index}`}
                                    className="scroll-block">
                                    <BlockStyles>
                                        <div className="block__container">
                                            <BlockType {...block} />
                                        </div>
                                    </BlockStyles>
                                </div>
                            );
                        })}
                    {show_facility_slider && facility_slider_position === 'bottom' && (
                        <FacilityCtaSection />
                    )}
                </div>
            </MaxWidth>
            <style jsx>
                {`
                    .blocks {
                        padding: 0 16px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .blocks {
                            padding: 0;
                        }
                    }
                    .scroll-block {
                        scroll-margin: 145px;
                    }
                    @media screen and (min-width: ${BREAKPOINT}) {
                        .scroll-block {
                            scroll-margin: 250px;
                        }
                    }
                `}
            </style>
        </Layout>
    );
};
export default PageBuilderTemplate;

const BlockStyles = styled.div`
    .accordion {
        border: none !important;
    }
    .block__container h2:not(.accordion__title) {
        margin-bottom: 40px;
    }
    .block__container small {
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
    }
`;
